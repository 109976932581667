import { useDevice } from "../../context/DeviceContext";
import React, { useState } from "react";

export const FAQHeader = ({ fontSize }) => {
  const { isExtraLargeScreen } = useDevice();
  return (
    <div
      style={{
        fontFamily: "Montserrat",
        fontSize: fontSize,
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "120%",
        letterSpacing: "-3px",
      }}
    >
      {isExtraLargeScreen && (
        <div>
          <span className="text-[#161616]">
            Frequently Asked <span className="text-[#6677F1]"> Questions</span>
          </span>
        </div>
      )}

      {!isExtraLargeScreen && (
        <div>
          <div className="text-[#161616]">Frequently Asked</div>
          <div className="text-[#6677F1]"> Questions</div>
        </div>
      )}
    </div>
  );
};

export const FAQDescription = () => {
  return (
    <div
      style={{
        color: "#161616",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",
      }}
    >
      We've compiled a list of common questions our clients ask about our
      services, processes, and what to expect when working with us. If you have
      any additional questions or need further clarification, please don't
      hesitate to contact us business@cityzenlabs.com. Get in touch with one of
      the leading Tampa Software Agencies for exceptional services.
    </div>
  );
};

export const FAQAccordian = ({ title, children, fontSize }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-gray-200">
      <div
        className="flex justify-between items-center cursor-pointer "
        onClick={toggleAccordion}
      >
        <div
          style={{
            fontFamily: "Montserrat",
            color: "#1B3275",
            fontSize: fontSize,
            fontWeight: "400",
            lineHeight: "120%",
            letterSpacing: "-0.75px",
          }}
        >
          {title}
        </div>
        <span className="text-2xl ml-[10px]">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && (
        <div
          className="my-[20px] "
          style={{
            fontFamily: "Montserrat",
            color: "#4F4F4F",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "150%",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export const FAQCost = ({ fontSize }) => {
  return (
    <FAQAccordian
      fontSize={fontSize}
      title="How much does it cost to develop a mobile app?"
    >
      <span>
        The cost of mobile app development varies, but we offer customizable
        pricing to fit different budgets and project scopes.
      </span>
    </FAQAccordian>
  );
};

export const FAQNativeAndHybrid = ({ fontSize }) => {
  return (
    <FAQAccordian
      fontSize={fontSize}
      title="How to choose between native and hybrid app development?"
    >
      <span>
        Native apps offer better performance, while hybrid apps are more
        cost-effective. We can guide you in choosing the best option.
      </span>
    </FAQAccordian>
  );
};

export const FAQTimeFrame = ({ fontSize }) => {
  return (
    <FAQAccordian
      fontSize={fontSize}
      title="What is the average time frame for mobile app development?"
    >
      <span>
        The timeline varies based on the app's complexity, but we are known for
        efficient and timely delivery of projects.
      </span>
    </FAQAccordian>
  );
};

export const FAQBenefits = ({ fontSize }) => {
  return (
    <FAQAccordian
      fontSize={fontSize}
      title="What are the benefits of hiring a professional app development team?"
    >
      <span>
        Professional teams like us offer expert knowledge, consistent quality,
        and dedicated project management for app development.
      </span>
    </FAQAccordian>
  );
};

export const FAQSupport = ({ fontSize }) => {
  return (
    <FAQAccordian
      fontSize={fontSize}
      title="Can an app development company assist with post-launch support and updates?"
    >
      <span>
        We offer comprehensive post-launch support and updates, ensuring your
        app remains current and functional.
      </span>
    </FAQAccordian>
  );
};
