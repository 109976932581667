import React from "react";
import { Element } from "react-scroll";
import Header from "../Header/Header";
import Landing from "../Landing/Landing";
import Services from "../Services/Services";
import Contact from "../Contact/Contact";
import Process from "../Process/Process";
import Footer from "../Footer/Footer";
import FAQ from "../FAQ/FAQ";
import Portfolio from "../Portfolio/Portfolio";

function Main() {
  return (
    <div>
      <Header />
      <Element name="landing">
        <Landing />
      </Element>
      <Element name="services">
        <Services />
      </Element>
      <Element name="process">
        <Process />
      </Element>
      <Element name="portfolio">
        <Portfolio />
      </Element>
      <Element name="contact">
        <Contact />
      </Element>
      <Element name="faq">
        <FAQ />
      </Element>
      <Footer />
    </div>
  );
}

export default Main;
