export const ServicesSubHeader = () => (
  <span
    className="py-[15px] px-[30px]"
    style={{
      borderBottom: "1px solid var(--Primary-Brand, #1B3275)",
      color: "#1B3275",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "100%",
    }}
  >
    Tailored Solutions
  </span>
);

export const ServicesHeader = ({ width }) => (
  <span
    className="py-[15px] px-[30px]"
    style={{
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "45px",
      fontWeight: "500",
      lineHeight: "120%", // 54px
      letterSpacing: "-3px",
      display: "inline-block",
      width: width,
    }}
  >
    <span
      style={{
        color: "var(--Primary-Dark, #161616)",
      }}
    >
      Our{" "}
    </span>
    <span
      style={{
        background:
          "var(--Primary-Gradient, linear-gradient(90deg, #6677F1 0%, #1B3275 100%))",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      Mobile App
    </span>
    <span
      style={{
        background:
          "var(--Primary-Gradient, linear-gradient(90deg, #6677F1 0%, #1B3275 100%))",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      {" "}
      and{" "}
    </span>
    <span
      style={{
        background:
          "var(--Primary-Gradient, linear-gradient(90deg, #6677F1 0%, #1B3275 100%))",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      Web Development
    </span>
    <span
      style={{
        color: "var(--Primary-Dark, #161616)",
      }}
    >
      {" "}
      Services
    </span>
  </span>
);

const servicesDetails = [
  {
    header: "Design",
    price: "$5,000",
    description:
      "After a consultation call and digesting your idea/project, our designers will build out a wireframe and clickable prototype to bring your project to life with color and branding",
    avgTime: "AVG. TIME: 2 WEEKS",
    avgPrice: "Avg",
    imageUrl: "designService",
  },
  {
    header: "Develop",
    price: "$20,0000-$60,0000",
    description:
      "After the design phase is done (wireframes are provided or done by our in-house team) our developers will build out your web or mobile application.",
    avgTime: "AVG. TIME: 3-6 MONTHS",
    avgPrice: "Avg",
    imageUrl: "developService",
  },
  {
    header: "Support",
    price: "$200-$1,000",
    description:
      "After successful development and launch of your application you will have the option to bring take over the hosting and management of your application or continue to use our in-house services for a monthly fee.",
    avgTime: "MONTHLY SUPPORT",
    avgPrice: "mo/Avg",
    imageUrl: "supportService",
  },
];

export const FullServicesDetails = () => (
  <div className="xl:flex gap-[20px] justify-between">
    {servicesDetails.map((detail) => (
      <div className="xl:mb-[0px] mb-[20px] bg-white w-full  p-[30px] ">
        <img
          src={`${process.env.PUBLIC_URL}/${detail.imageUrl}.png`}
          alt="Computer"
          className="pb-[24px]"
        />
        <div className="flex items-end mb-[24px]">
          <span
            style={{
              fontFamily: "Montserrat",
              fontSize: "28px",
              fontWeight: "400",
              lineHeight: "normal",
              color: "#6677F1 ",
            }}
          >
            {detail.price}
          </span>
          <span
            style={{
              fontFamily: "Montserrat",
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "150%",
              color: "#161616",
            }}
          >
            {detail.avgPrice}
          </span>
        </div>
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "25px",
            fontWeight: "500",
            lineHeight: "120%",
            color: "#161616",
            letterSpacing: "-0.75px",
          }}
          className="mb-[24px]"
        >
          {detail.header}
        </div>
        <div
          className="mb-[24px] xl:h-[110px]"
          style={{
            fontFamily: "Montserrat",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "140%",
            color: "#161616",
            overflowY: "auto",
          }}
        >
          {detail.description}
        </div>
        <div
          className=" p-[10px]"
          style={{
            fontFamily: "Montserrat",
            fontSize: "13px",
            fontWeight: "400",
            lineHeight: "120%",
            letterSpacing: "-0.39px",
            color: "#1B3275",
            overflowY: "auto",
            width: "fit-content",
            borderRadius: "5px",
            backgroundColor: "#F6F6FF",
          }}
        >
          {detail.avgTime}
        </div>
      </div>
    ))}
  </div>
);
