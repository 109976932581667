import React from "react";
import { Logo } from "../../Logo/Logo";
import { ArrowRightIcon, MenuIcon } from "../../icons/icons";
import {
  buttonLabel,
  headerMenuItems,
} from "../../constants/Header/HeaderConstants";
import { useDevice } from "../../context/DeviceContext";
import { Link } from "react-scroll";

function Header() {
  const { isExtraLargeScreen, isMobile } = useDevice();

  return (
    <div style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}>
      {isExtraLargeScreen && (
        <div
          className={`flex items-center text-center justify-between px-[120px] py-[28px]`}
        >
          <Logo />
          <div className="flex gap-[40px]">
            {headerMenuItems.map((item) => (
              <Link
                key={item.id}
                to={item.name} // This should match the 'name' prop in the Element
                smooth={true}
                duration={500}
                className="cursor-pointer"
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "normal",
                  color: "#161616",
                }}
              >
                {item.label}
              </Link>
            ))}
          </div>
          <div>
            <button
              style={{
                fontFamily: "Montserrat",
                fontSize: "15px",
                fontWeight: "500",
                lineHeight: "24px",
                borderRadius: "5px",
                border: "1px solid var(--Primary-Gradient, #6677F1)",
              }}
              type="button"
            >
              <div className="flex items-center">
                <div className="pl-[20px] pr-[6px]">{buttonLabel}</div>
                <div className="p-[15px]">
                  <ArrowRightIcon color="black" />
                </div>
              </div>
            </button>
          </div>
        </div>
      )}

      {!isExtraLargeScreen && (
        <div
          className={`flex items-center text-center justify-between ${
            isMobile ? "px-[20px]" : "px-[120px]"
          } py-[28px]`}
        >
          <Logo />
          <MenuIcon />
        </div>
      )}
    </div>
  );
}

export default Header;
