export const ArrowRightIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z"
        fill={color}
      />
    </svg>
  );
};

export const MenuIcon = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "5.895px",
        flexShrink: 0,
        alignSelf: "stretch",
        width: "20px", // Adjust as needed
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="2"
        viewBox="0 0 20 2"
        fill="none"
      >
        <path d="M0 0H20V2H0V0Z" fill="#23252B" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="2"
        viewBox="0 0 20 2"
        fill="none"
      >
        <path d="M0 0H20V2H0V0Z" fill="#23252B" />
      </svg>
    </div>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.4618 8.63751L8.63776 8.63751L8.63776 12.4615H7.36308L7.36308 8.63751H3.53906L3.53906 7.36284H7.36308V3.53882L8.63776 3.53882V7.36284L12.4618 7.36284V8.63751Z"
        fill="#161616"
      />
    </svg>
  );
};

export const FaceBook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="19"
      viewBox="0 0 10 19"
      fill="none"
    >
      <path
        d="M6.39407 18.4453V10.2353H9.14874L9.56202 7.03472H6.39407V4.99163C6.39407 4.06529 6.65025 3.434 7.98013 3.434L9.67352 3.4333V0.570619C9.38067 0.532563 8.37543 0.445312 7.20543 0.445312C4.7623 0.445312 3.08968 1.93658 3.08968 4.67465V7.03472H0.32666V10.2353H3.08968V18.4453H6.39407Z"
        fill="white"
      />
    </svg>
  );
};

export const Twitter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <g clip-path="url(#clip0_79_1758)">
        <path
          d="M13.683 2.13281H16.165L10.7439 8.32734L17.1213 16.7578H12.1291L8.21621 11.6461L3.74434 16.7578H1.25879L7.05605 10.1309L0.942383 2.13281H6.06113L9.59433 6.80508L13.683 2.13281ZM12.8111 15.2742H14.1857L5.3123 3.53906H3.83574L12.8111 15.2742Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_79_1758">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.445312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LinkedIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <g clip-path="url(#clip0_79_1765)">
        <path
          d="M17.9959 18.4455L18.0004 18.4447V11.8432C18.0004 8.61373 17.3052 6.12598 13.5297 6.12598C11.7147 6.12598 10.4967 7.12198 9.99944 8.06623H9.94694V6.42748H6.36719V18.4447H10.0947V12.4942C10.0947 10.9275 10.3917 9.41248 12.3319 9.41248C14.2437 9.41248 14.2722 11.2005 14.2722 12.5947V18.4455H17.9959Z"
          fill="white"
        />
        <path
          d="M0.297363 6.42773H4.02936V18.445H0.297363V6.42773Z"
          fill="white"
        />
        <path
          d="M2.16199 0.445312C0.968738 0.445312 0.000488281 1.41356 0.000488281 2.60681C0.000488281 3.80006 0.968738 4.78856 2.16199 4.78856C3.35524 4.78856 4.32349 3.80006 4.32349 2.60681C4.32274 1.41356 3.35449 0.445312 2.16199 0.445312Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_79_1765">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.000488281 0.445312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const GitHub = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3 .3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5 .3-6.2 2.3zm44.2-1.7c-2.9 .7-4.9 2.6-4.6 4.9 .3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3 .7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3 .3 2.9 2.3 3.9 1.6 1 3.6 .7 4.3-.7 .7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3 .7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3 .7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
    </svg>
  );
};
