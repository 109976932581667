import React, { useState } from "react";
import { ArrowRightIcon, PlusIcon } from "../../icons/icons";

export const ContactSubHeader = () => {
  return (
    <span
      className="pr-[30px] pb-[15px] border-b "
      style={{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "100%",
        borderBottom: "1px solid var(--Primary-Brand, #1B3275)",
      }}
    >
      Contact Us
    </span>
  );
};

export const ContactHeader = ({ fontSize }) => {
  return (
    <span>
      <span
        style={{
          fontFamily: "Montserrat",
          fontSize: fontSize,
          fontWeight: "500",
          lineHeight: "120%",
          letterSpacing: "-3px",
          color: "#161616",
        }}
      >
        Exploring Our App Development Services? <span></span>
      </span>
      <span
        style={{
          fontFamily: "Montserrat",
          fontSize: fontSize,
          fontWeight: "500",
          lineHeight: "120%",
          letterSpacing: "-3px",
          color: "#6677F1",
        }}
      >
        Share Your Project Details!
      </span>
    </span>
  );
};

export const ContactDetails = [
  "We’ll hop on a call and hear out your idea, protected by our NDA",
  "We’ll provide a free analysis & quote + send over a proposal",
  "Even if we don’t work together, feel free to consider us a free technical resource to bounce your thoughts/questions off of.",
];

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    nameOrCompany: "",
    email: "",
    phone: "",
    interestedIn: "",
    projectBudget: "",
    projectDetails: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit action

    // URL of the API endpoint
    const url = process.env.REACT_APP_API_URL;
    console.log(url);

    // Form data object to be sent in the POST request
    const requestData = {
      name: formData.nameOrCompany,
      email: formData.email,
      phoneNumber: formData.phone,
      projectType: formData.interestedIn,
      projectBudget: formData.projectBudget,
      projectDetails: formData.projectDetails,
    };

    try {
      const response = await fetch(url + "/cityzen-labs/client", {
        method: "POST", // Specify the method
        headers: {
          "Content-Type": "application/json", // Specify the content type
        },
        body: JSON.stringify(requestData), // Convert the JavaScript object to a JSON string
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json(); // Assuming the server responds with JSON
      console.log("Success:", result);
      // Handle success, perhaps reset form or give user feedback
    } catch (error) {
      console.error("Error:", error);
      // Handle errors, perhaps show user an error message
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          fontFamily: "Montserrat",
          fontSize: "15px",
          fontWeight: "500",
          lineHeight: "120%",
          letterSpacing: "-0.45px",
          color: "#1B3275",
        }}
        className="mb-[10px]"
      >
        Name/Company*
      </div>
      <div className="mb-[20px]">
        <input
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "300",
            lineHeight: "120%",
            letterSpacing: "0.45px",
            borderRadius: "10px",
            border: "1px solid rgba(79, 79, 79, 0.5",
          }}
          className="w-full px-[25px] py-[20px] bg-[#F6F6FF]"
          type="text"
          name="nameOrCompany"
          value={formData.nameOrCompany}
          onChange={handleInputChange}
          placeholder="Eg; John Doe"
          required
        />
      </div>

      <div className="mb-[20px]">
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "120%",
            letterSpacing: "-0.45px",
            color: "#1B3275",
          }}
          className="mb-[10px]"
        >
          Email Address*
        </div>
        <input
          className="w-full px-[25px] py-[20px] bg-[#F6F6FF]"
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "300",
            lineHeight: "120%",
            letterSpacing: "0.45px",
            borderRadius: "10px",
            border: "1px solid rgba(79, 79, 79, 0.5",
          }}
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Eg; hello@domain.com"
          required
        />
      </div>

      <div className="mb-[20px]">
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "120%",
            letterSpacing: "-0.45px",
            color: "#1B3275",
          }}
          className="mb-[10px]"
        >
          Phone Number (Optional)
        </div>
        <input
          className="w-full px-[25px] py-[20px] bg-[#F6F6FF]"
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "300",
            lineHeight: "120%",
            letterSpacing: "0.45px",
            borderRadius: "10px",
            border: "1px solid rgba(79, 79, 79, 0.5",
          }}
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          placeholder="Eg; +123 4567 8901"
        />
      </div>

      <div className="mb-[20px]">
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "120%",
            letterSpacing: "-0.45px",
            color: "#1B3275",
          }}
          className="mb-[10px]"
        >
          Project Type*
        </div>{" "}
        <div
          style={{
            position: "relative", // Needed for absolute positioning of the icon
            display: "inline-block", // Keeps the container inline with the layout
            width: "100%", // Ensures the select fills the container
          }}
        >
          <select
            className="w-full px-[25px] py-[20px] bg-[#F6F6FF]"
            style={{
              fontFamily: "Montserrat",
              fontSize: "15px",
              fontWeight: "300",
              lineHeight: "120%",
              letterSpacing: "0.45px",
              borderRadius: "10px",
              border: "1px solid rgba(79, 79, 79, 0.5)",
              color: "#888",
              appearance: "none", // Hide the default dropdown arrow
              paddingRight: "40px", // Make room for the icon inside the select
            }}
            name="interestedIn"
            value={formData.interestedIn}
            onChange={handleInputChange}
            required
          >
            <option value="">Select One</option>
            <option value="design">Design</option>
            <option value="development">Development</option>
            <option value="full application">Full Application</option>
          </select>
          <div
            style={{
              position: "absolute",
              right: "10px", // Right padding inside the select box
              top: "50%", // Center vertically
              transform: "translateY(-50%)", // Exact vertical centering
              pointerEvents: "none", // Prevents the icon from blocking clicks on the select
            }}
          >
            <PlusIcon />
          </div>
        </div>
      </div>
      <div className="mb-[20px]">
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "120%",
            letterSpacing: "-0.45px",
            color: "#1B3275",
          }}
          className="mb-[10px]"
        >
          Project Budget*
        </div>
        <div
          style={{
            position: "relative", // Needed for absolute positioning of the icon
            display: "inline-block", // Keeps the container inline with the layout
            width: "100%", // Ensures the select fills the container
          }}
        >
          <select
            className="w-full px-[25px] py-[20px] bg-[#F6F6FF]"
            style={{
              fontFamily: "Montserrat",
              fontSize: "15px",
              fontWeight: "300",
              lineHeight: "120%",
              letterSpacing: "0.45px",
              borderRadius: "10px",
              border: "1px solid rgba(79, 79, 79, 0.5)",
              color: formData.projectBudget === "Select One" ? "" : "#888",
              appearance: "none", // Hide the default dropdown arrow
              paddingRight: "40px", // Make room for the icon inside the select
            }}
            name="projectBudget"
            value={formData.projectBudget}
            onChange={handleInputChange}
            required
          >
            <option value="">Select One</option>
            <option value="small">Less than $5,000</option>
            <option value="medium">$5,000 to $40,000</option>
            <option value="large">More than $40,000</option>
          </select>
          <div
            style={{
              position: "absolute",
              right: "10px", // Right padding inside the select box
              top: "50%", // Center vertically
              transform: "translateY(-50%)", // Exact vertical centering
              pointerEvents: "none", // Prevents the icon from blocking clicks on the select
            }}
          >
            <PlusIcon />
          </div>
        </div>
      </div>

      <div className="mb-[30px]">
        <div
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "500",
            lineHeight: "120%",
            letterSpacing: "-0.45px",
            color: "#1B3275",
          }}
          className="mb-[10px]"
        >
          Tell us about your project*
        </div>{" "}
        <textarea
          className="w-full px-[25px] py-[20px] bg-[#F6F6FF]"
          style={{
            fontFamily: "Montserrat",
            fontSize: "15px",
            fontWeight: "300",
            lineHeight: "120%",
            letterSpacing: "0.45px",
            borderRadius: "10px",
            border: "1px solid rgba(79, 79, 79, 0.5",
          }}
          name="projectDetails"
          value={formData.projectDetails}
          onChange={handleInputChange}
          placeholder="Eg; Hello, Cityzen Labs. We need help with the design and development of our project."
          required
        />
      </div>

      <button
        style={{
          fontFamily: "Montserrat",
          fontSize: "15px",
          fontWeight: "500",
          lineHeight: "24px",
          borderRadius: "5px",
          color: "white",
          background:
            "var(--Primary-Gradient, linear-gradient(90deg, #6677F1 0%, #1B3275 100%))",
        }}
        type="submit"
      >
        <div className="flex items-center">
          <div className="pl-[20px] pr-[6px]">Get In Touch</div>
          <div className="p-[15px]">
            <ArrowRightIcon color="white" />
          </div>
        </div>
      </button>
    </form>
  );
};
