import React from "react";
import { useDevice } from "../../context/DeviceContext";
import {
  ContactSubHeader,
  ContactHeader,
  ContactDetails,
  ContactForm,
} from "../../constants/Contact/ContactConstants";

function Contact() {
  const { isMobile, isExtraLargeScreen } = useDevice();
  return (
    <div className="bg-[#F6F6FF]">
      <div style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}>
        {" "}
        <div
          className={` ${
            isMobile ? "px-[20px] py-[100px]" : "px-[120px] py-[120px]"
          }`}
        >
          {isExtraLargeScreen && (
            <div className="flex justify-between gap-[80px]">
              <div>
                <div className="mb-[35px]">
                  <ContactSubHeader />
                </div>
                <div className="mb-[60px]">
                  <ContactHeader fontSize={"45px"} />
                </div>
                <div>
                  {ContactDetails.map((detail) => (
                    <div className="flex items-start mb-[30px] w-[500px]">
                      {" "}
                      {/* Adjust here for top alignment */}
                      <img
                        src={`${process.env.PUBLIC_URL}/check.png`}
                        alt="Check"
                        style={{ width: "auto" }}
                        className="mr-[20px]" // Example fixed height for control
                      />
                      <div
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "20px",
                          fontWeight: "300",
                          lineHeight: "120%", // 54px
                          letterSpacing: "-0.6px",
                        }}
                      >
                        {detail}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full">
                <ContactForm />
              </div>
            </div>
          )}

          {!isExtraLargeScreen && (
            <div>
              <div className="mb-[35px]">
                <ContactSubHeader />
              </div>
              <div className="mb-[60px]">
                <ContactHeader fontSize={"35px"} />
              </div>
              <div className="w-full mb-[60px]">
                <ContactForm />
              </div>
              <div>
                {ContactDetails.map((detail) => (
                  <div className="flex items-start mb-[30px]">
                    {" "}
                    {/* Adjust here for top alignment */}
                    <img
                      src={`${process.env.PUBLIC_URL}/check.png`}
                      alt="Check"
                      style={{ width: "auto" }}
                      className="mr-[20px]" // Example fixed height for control
                    />
                    <div
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontWeight: "300",
                        lineHeight: "120%", // 54px
                        letterSpacing: "-0.6px",
                      }}
                    >
                      {detail}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contact;
