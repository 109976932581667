import React from "react";
import { useDevice } from "../../context/DeviceContext";
import {
  ProcessButtons,
  ProcessHeader,
  ProcessSubHeader,
} from "../../constants/Process/ProcessConstants";

function Process() {
  const { isMobile, isExtraLargeScreen } = useDevice();
  return (
    <div
      style={{
        background:
          "var(--Secondary-Gradient, linear-gradient(128deg, #1B3275 -13.95%, #6971F1 117.91%))",
      }}
    >
      <div style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}>
        {" "}
        <div
          className={` ${
            isMobile ? "px-[20px] py-[100px]" : "px-[120px] py-[120px]"
          }`}
        >
          {" "}
          <div className="flex justify-center mb-[20px]">
            <ProcessSubHeader />
          </div>
          <div
            className={`flex justify-center ${
              isExtraLargeScreen ? "mb-[80px]" : "mb-[60px]"
            }`}
          >
            <ProcessHeader width={isExtraLargeScreen ? "850px" : ""} />
          </div>
          <div>
            <ProcessButtons />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Process;
