import React from "react";

export const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="204"
    height="45"
    viewBox="0 0 204 45"
    fill="none"
  >
    <path
      d="M29.742 6.33901C29.742 4.84445 29.7481 3.34989 29.7389 1.85533C29.7359 0.9048 28.8954 0.220167 27.9968 0.510521C27.7279 0.5961 27.3978 1.03011 27.3917 1.30824C27.3428 3.53327 27.3428 5.7583 27.3917 7.98334C27.3978 8.27981 27.6759 8.69547 27.9479 8.84523C29.8184 9.87829 29.8306 9.85994 31.3588 8.33482C32.5569 7.13978 33.7581 5.9478 34.9531 4.74971C35.3382 4.36461 35.7478 3.97645 35.2037 3.40185C34.5222 2.6836 33.8284 2.53995 33.269 3.09315C32.1535 4.19039 31.0746 5.3243 29.9804 6.44293C29.9009 6.40931 29.8215 6.37569 29.742 6.33901Z"
      fill="#1B3275"
    />
    <path
      d="M24.8824 4.04363C24.8824 3.01057 24.8732 1.97752 24.8854 0.944467C24.8946 0.110079 24.3964 0.00310768 23.7087 5.131e-05C23.0149 -0.00300506 22.532 0.128414 22.5351 0.950576C22.5412 3.0503 22.5443 5.14697 22.532 7.24669C22.5259 8.08719 23.0333 8.185 23.7087 8.18806C24.3903 8.19111 24.8946 8.07496 24.8885 7.24363C24.8793 6.18002 24.8854 5.11335 24.8854 4.04974C24.8854 4.04362 24.8824 4.04363 24.8824 4.04363Z"
      fill="#1B3275"
    />
    <path
      d="M16.4927 5.92639C15.4505 4.85666 14.4083 3.78388 13.3599 2.72027C12.6936 2.04481 11.6147 2.13956 11.1716 2.97395C11.0401 3.22457 11.1043 3.76554 11.2969 3.97032C12.8159 5.5963 14.3716 7.18867 15.9578 8.74742C16.1718 8.95525 16.6608 9.05917 16.9573 8.97664C19.0173 8.40816 19.0111 8.38983 19.0417 6.22898C19.0662 4.53575 19.0906 2.84557 19.1089 1.15235C19.1151 0.608316 19.1364 0.042894 18.3479 0.0123303C17.3576 -0.0274024 16.7617 0.354638 16.7464 1.14318C16.7158 2.70804 16.7341 4.2729 16.7341 5.8347C16.6547 5.86832 16.5722 5.89583 16.4927 5.92639Z"
      fill="#1B3275"
    />
    <path
      d="M11.41 7.67773C10.6887 6.93809 9.95818 6.20456 9.24605 5.45575C8.67145 4.85059 8.23746 5.12261 7.74538 5.5994C7.2472 6.08231 6.99351 6.51326 7.56811 7.10008C9.03822 8.5977 10.5053 10.0984 11.9601 11.6082C12.5439 12.2134 12.9748 11.9291 13.4608 11.4584C13.9498 10.9847 14.231 10.5477 13.6442 9.95777C12.8954 9.20591 12.1588 8.43876 11.41 7.67773C11.413 7.67773 11.413 7.67773 11.41 7.67773Z"
      fill="#1B3275"
    />
    <path
      d="M6.72136 14.8877C5.2268 14.851 3.73224 14.8112 2.24073 14.7807C1.2902 14.7623 0.58418 15.5845 0.85314 16.4892C0.932605 16.7612 1.35744 17.1005 1.63557 17.1158C3.85755 17.2197 6.08563 17.2716 8.31067 17.2808C8.60714 17.2808 9.03198 17.0118 9.18785 16.7459C10.2667 14.8999 10.2484 14.8907 8.75995 13.3228C7.59548 12.0941 6.431 10.8655 5.26347 9.64294C4.88754 9.24867 4.50855 8.82995 3.92173 9.3587C3.18515 10.0219 3.02621 10.7127 3.56413 11.2873C4.63386 12.4273 5.74027 13.5337 6.83139 14.6554C6.79471 14.7318 6.75804 14.8082 6.72136 14.8877Z"
      fill="#1B3275"
    />
    <path
      d="M4.30696 19.6892C3.27391 19.6647 2.24085 19.6495 1.2078 19.6097C0.373414 19.5792 0.254218 20.0774 0.23588 20.762C0.214485 21.4558 0.33368 21.9417 1.1589 21.9601C3.25557 22.0059 5.35529 22.0548 7.45195 22.119C8.29245 22.1435 8.40249 21.6392 8.42083 20.9637C8.43916 20.2821 8.33525 19.7748 7.50392 19.7626C6.4403 19.7473 5.37363 19.7136 4.31001 19.6861C4.30695 19.6861 4.30696 19.6892 4.30696 19.6892Z"
      fill="#1B3275"
    />
    <path
      d="M5.98497 28.1217C4.89079 29.1394 3.79356 30.1541 2.70244 31.175C2.00864 31.826 2.07893 32.9049 2.90415 33.3664C3.15171 33.5039 3.69269 33.4519 3.90053 33.2655C5.56319 31.7862 7.19223 30.2703 8.79071 28.7207C9.00466 28.5129 9.11775 28.0269 9.0444 27.7274C8.52787 25.6521 8.50647 25.6582 6.34868 25.5757C4.65851 25.5115 2.96528 25.4443 1.27511 25.3831C0.731077 25.3648 0.165647 25.3281 0.116745 26.1167C0.0525618 27.1039 0.422384 27.7121 1.20787 27.7457C2.76967 27.816 4.33453 27.8343 5.89939 27.8741C5.92996 27.9597 5.95746 28.0422 5.98497 28.1217Z"
      fill="#1B3275"
    />
    <path
      d="M7.61402 33.2441C6.85604 33.947 6.10723 34.6592 5.34008 35.353C4.7227 35.9123 4.98249 36.3524 5.44706 36.8567C5.91774 37.3671 6.34258 37.633 6.94163 37.0707C8.47286 35.6372 10.0102 34.2068 11.5567 32.7887C12.1772 32.2202 11.9021 31.7831 11.4436 31.285C10.9821 30.7837 10.5512 30.4934 9.94909 31.0649C9.17583 31.7984 8.39034 32.5167 7.61402 33.2441C7.61096 33.2441 7.61096 33.2441 7.61402 33.2441Z"
      fill="#1B3275"
    />
    <path
      d="M14.7049 38.1099C14.6315 39.6014 14.5551 41.096 14.4879 42.5875C14.4451 43.535 15.2489 44.2624 16.1628 44.0148C16.4348 43.9415 16.7863 43.5258 16.8077 43.2477C16.9666 41.0287 17.0736 38.8037 17.1347 36.5787C17.1439 36.2822 16.8841 35.8513 16.6212 35.6893C14.8027 34.5676 14.7905 34.5829 13.1889 36.0316C11.9328 37.1655 10.6766 38.2994 9.42349 39.4394C9.02005 39.8062 8.59216 40.173 9.10563 40.772C9.75052 41.5239 10.4382 41.7011 11.025 41.1754C12.1926 40.1332 13.3234 39.0543 14.4726 37.9907C14.549 38.0335 14.6285 38.0702 14.7049 38.1099Z"
      fill="#1B3275"
    />
    <path
      d="M19.4454 40.6375C19.3965 41.6706 19.3537 42.7036 19.2926 43.7336C19.2406 44.568 19.7357 44.6994 20.4204 44.7361C21.1142 44.7728 21.6032 44.6658 21.6398 43.8437C21.7346 41.747 21.8354 39.6503 21.9516 37.5567C21.9974 36.7162 21.4962 36.594 20.8207 36.5603C20.1392 36.5237 19.6288 36.6154 19.5982 37.4467C19.5554 38.5103 19.4973 39.5739 19.4454 40.6375Z"
      fill="#1B3275"
    />
    <path
      d="M27.9176 39.1673C28.9078 40.286 29.895 41.4076 30.8884 42.5232C31.521 43.2323 32.603 43.1895 33.0859 42.3765C33.2295 42.132 33.1929 41.591 33.0095 41.3771C31.573 39.6777 30.0968 38.012 28.5869 36.3769C28.3852 36.1568 27.8992 36.0315 27.5997 36.0987C25.5122 36.5664 25.5183 36.5847 25.3838 38.7425C25.2769 40.4327 25.1699 42.1198 25.069 43.8099C25.0354 44.354 24.9865 44.9164 25.7751 44.9867C26.7623 45.0753 27.3766 44.7207 27.4316 43.9353C27.5386 42.3735 27.5967 40.8116 27.6731 39.2498C27.7556 39.2193 27.8381 39.1948 27.9176 39.1673Z"
      fill="#1B3275"
    />
    <path
      d="M33.0797 37.6667C33.7643 38.4399 34.4581 39.2071 35.1335 39.9895C35.6776 40.6222 36.1238 40.3716 36.6403 39.9192C37.163 39.4608 37.438 39.0451 36.8909 38.4308C35.4942 36.8629 34.1035 35.2949 32.7221 33.7148C32.1689 33.0821 31.7226 33.345 31.2153 33.7912C30.7018 34.2405 30.4023 34.6623 30.9585 35.2797C31.6707 36.0682 32.3706 36.869 33.0797 37.6667C33.0766 37.6667 33.0766 37.6667 33.0797 37.6667Z"
      fill="#1B3275"
    />
    <path
      d="M43.0801 21.954C43.0801 16.043 47.9122 11.3943 53.6704 11.3943C57.4511 11.3943 60.8406 13.3443 62.6408 16.584C63.1818 17.4856 62.8211 18.4148 62.0998 18.7448C61.4091 19.1055 60.48 18.9252 60.1193 18.2039C58.7103 15.8627 56.3386 14.4537 53.6673 14.4537C49.5565 14.4537 46.1364 17.8432 46.1364 21.954C46.1364 26.0954 49.5565 29.3352 53.6673 29.3352C56.1583 29.3352 58.5269 28.0759 59.939 25.9151C60.2996 25.1938 61.4091 25.0134 61.9195 25.5544C62.6408 25.9151 62.9984 26.8442 62.4605 27.535C60.48 30.5944 57.2708 32.3946 53.6704 32.3946C47.9122 32.3976 43.0801 27.7153 43.0801 21.954Z"
      fill="#161616"
    />
    <path
      d="M65.899 14.0075C65.6392 13.7477 65.468 13.3748 65.468 13.0019C65.468 12.629 65.6392 12.2562 65.899 11.9964C66.1588 11.7366 66.5316 11.5654 66.9045 11.5654C67.2774 11.5654 67.6503 11.7366 67.9101 11.9964C68.1698 12.2562 68.3104 12.629 68.3104 13.0019C68.3104 13.3748 68.1668 13.7477 67.9101 14.0075C67.6503 14.2673 67.2774 14.4078 66.9045 14.4078C66.5316 14.4078 66.1557 14.2673 65.899 14.0075ZM65.468 30.2582V18.6898C65.468 17.9135 66.1007 17.2839 66.9045 17.2839C67.6808 17.2839 68.3104 17.9166 68.3104 18.6898V30.2582C68.3104 31.062 67.6778 31.6946 66.9045 31.6946C66.1007 31.6916 65.468 31.062 65.468 30.2582Z"
      fill="#161616"
    />
    <path
      d="M79.8513 18.6867C79.8513 19.463 79.2186 20.1232 78.4148 20.1232H76.3762V30.2581C76.3762 31.0344 75.7435 31.6946 74.9397 31.6946C74.1634 31.6946 73.5338 31.0344 73.5338 30.2581V20.1232H71.4677C70.6914 20.1232 70.0618 19.463 70.0618 18.6867C70.0618 17.9104 70.6944 17.2808 71.4677 17.2808H73.5338V11.5379C73.5338 10.7616 74.1665 10.132 74.9397 10.132C75.7435 10.132 76.3762 10.7646 76.3762 11.5379V17.2808H78.4148C79.2186 17.2808 79.8513 17.9135 79.8513 18.6867Z"
      fill="#161616"
    />
    <path
      d="M93.1432 18.7754V32.9294C93.1432 36.1722 90.475 38.8434 87.2016 38.8434C84.9338 38.8434 82.8952 37.5506 81.8897 35.512C81.5443 34.8243 81.8316 33.9624 82.5498 33.6171C83.2375 33.2717 84.0994 33.5896 84.4173 34.2772C84.9644 35.3408 86.0249 36.001 87.2016 36.001C88.9254 36.001 90.3008 34.6226 90.3008 32.9294V30.8633C89.4389 31.3798 88.4333 31.6671 87.3422 31.6671C84.185 31.6671 81.5993 29.112 81.5718 25.9547C81.5718 25.9272 81.5718 25.9272 81.5718 25.9272V18.7784C81.5718 18.0021 82.2045 17.3725 82.9777 17.3725C83.754 17.3725 84.3836 18.0051 84.3836 18.7784V25.8997C84.3836 27.5349 85.7315 28.8552 87.3392 28.8552C88.9743 28.8552 90.2977 27.5349 90.2977 25.8997V18.7784C90.2977 18.0021 90.9304 17.3725 91.7036 17.3725C92.5105 17.3664 93.1432 17.999 93.1432 18.7754Z"
      fill="#161616"
    />
    <path
      d="M107.029 30.2276C107.029 31.0039 106.396 31.6641 105.623 31.6641H96.695C96.1509 31.6641 95.6313 31.3493 95.4021 30.8602C95.1423 30.3437 95.2004 29.7691 95.5458 29.3382L102.722 20.0927H96.6369C95.8606 20.0927 95.231 19.4325 95.231 18.6562C95.231 17.8799 95.8636 17.2502 96.6369 17.2502H105.565C106.112 17.2502 106.628 17.5375 106.857 18.0541C107.117 18.5431 107.059 19.1177 106.714 19.5456L99.5374 28.8186H105.623C106.399 28.8216 107.029 29.4543 107.029 30.2276Z"
      fill="#161616"
    />
    <path
      d="M123.273 24.4572C123.273 25.2335 122.641 25.8631 121.837 25.8631H111.931C112.533 27.5869 114.141 28.8186 116.066 28.8186C116.726 28.8186 117.903 28.7605 119.282 27.8711C119.942 27.4402 120.831 27.8436 121.177 28.5313C121.522 29.2495 121.149 29.9953 120.489 30.4262C118.594 31.6885 117.13 31.661 116.066 31.661C112.075 31.661 108.859 28.4182 108.859 24.4541C108.859 20.4625 112.075 17.2472 116.066 17.2472C119.538 17.2533 123.273 19.7779 123.273 24.4572ZM111.931 23.0237H120.226C119.624 21.0157 117.787 20.0957 116.063 20.0957C114.141 20.0957 112.536 21.3305 111.931 23.0237Z"
      fill="#161616"
    />
    <path
      d="M139.771 24.4571V30.2276C139.771 31.0314 139.139 31.6641 138.335 31.6641C137.558 31.6641 136.929 31.0314 136.929 30.2276V24.4571C136.929 22.0457 134.976 20.0927 132.564 20.0927C130.153 20.0927 128.2 22.0457 128.2 24.4571V30.2276C128.2 31.0314 127.567 31.6641 126.763 31.6641C125.987 31.6641 125.357 31.0314 125.357 30.2276V24.4571C125.357 20.4655 128.6 17.2502 132.564 17.2502C136.528 17.2502 139.771 20.4686 139.771 24.4571Z"
      fill="#161616"
    />
    <path
      d="M161.31 30.961C161.31 31.7648 160.649 32.3975 159.873 32.3975H149.967C149.94 32.3975 149.94 32.3975 149.909 32.3975H149.882C149.078 32.3975 148.445 31.7648 148.445 30.961V13.7934C148.445 12.9896 149.078 12.3569 149.882 12.3569C150.658 12.3569 151.318 12.9896 151.318 13.7934V29.5245H159.873C160.649 29.5245 161.31 30.1572 161.31 30.961Z"
      fill="#161616"
    />
    <path
      d="M176.34 25.3068V30.9336C176.34 31.7649 175.738 32.3701 174.904 32.3701C174.329 32.3701 173.526 31.768 173.526 30.9336C172.291 31.9667 170.912 32.3701 169.277 32.3701C165.231 32.3701 162.012 29.3565 162.012 25.3068C162.012 21.2602 165.228 18.0449 169.277 18.0449C173.324 18.0419 176.34 21.2572 176.34 25.3068ZM173.526 25.3068C173.526 22.8954 171.689 20.8568 169.277 20.8568C166.866 20.8568 164.827 22.8954 164.827 25.3068C164.827 27.7183 166.866 29.5552 169.277 29.5552C171.689 29.5552 173.526 27.7183 173.526 25.3068Z"
      fill="#161616"
    />
    <path
      d="M193.148 25.1326C193.148 29.0937 189.932 32.3395 185.941 32.3395C181.98 32.3395 178.734 29.0967 178.734 25.1326V25.1051V12.2439C178.734 11.4676 179.367 10.838 180.17 10.838C180.947 10.838 181.576 11.4707 181.576 12.2439V19.3928C182.781 18.4728 184.303 17.9288 185.941 17.9288C189.932 17.9288 193.148 21.1441 193.148 25.1326ZM190.305 25.1326C190.305 22.7212 188.352 20.7681 185.941 20.7681C183.529 20.7681 181.576 22.7212 181.576 25.1021V25.1296C181.576 27.541 183.529 29.4941 185.941 29.4941C188.352 29.4941 190.305 27.5166 190.305 25.1326Z"
      fill="#161616"
    />
    <path
      d="M203.564 28.0911C203.564 30.4751 201.641 32.425 199.257 32.425C197.219 32.425 195.583 30.961 195.036 29.2953C194.776 28.5496 195.382 27.7733 196.127 27.6021C196.904 27.4584 197.448 28.0025 197.821 28.7207C198.221 29.4665 198.566 29.5826 199.257 29.5826C200.061 29.5826 200.721 28.9224 200.721 28.0911C200.721 27.2873 200.061 26.6271 199.257 26.6271C198.108 26.6271 197.017 26.1687 196.213 25.3648C195.382 24.561 194.951 23.4699 194.951 22.3207C194.951 19.9367 196.873 18.0143 199.257 18.0143C201.268 18.0143 203.105 19.3927 203.392 21.0003C203.536 21.7767 203.078 22.5499 202.301 22.6936C201.525 22.8647 200.865 22.3482 200.608 21.63C200.437 21.1715 199.948 20.8536 199.26 20.8536C198.456 20.8536 197.796 21.5138 197.796 22.3176C197.796 23.1215 198.456 23.7816 199.26 23.7816C200.409 23.7816 201.5 24.2401 202.304 25.0439C203.133 25.8783 203.564 26.9419 203.564 28.0911Z"
      fill="#161616"
    />
  </svg>
);

export const WhiteLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="204"
    height="46"
    viewBox="0 0 204 46"
    fill="none"
  >
    <path
      d="M29.6329 6.76408C29.6329 5.26952 29.639 3.77495 29.6298 2.28038C29.6268 1.32985 28.7863 0.645215 27.8877 0.935571C27.6187 1.02115 27.2886 1.45516 27.2825 1.73329C27.2336 3.95833 27.2336 6.18337 27.2825 8.40842C27.2886 8.70489 27.5668 9.12055 27.8388 9.27032C29.7093 10.3034 29.7215 10.285 31.2497 8.7599C32.4478 7.56486 33.6489 6.37287 34.844 5.17477C35.2291 4.78967 35.6386 4.40151 35.0946 3.82691C34.413 3.10866 33.7192 2.96501 33.1599 3.51821C32.0443 4.61545 30.9654 5.74937 29.8713 6.868C29.7918 6.83438 29.7123 6.80076 29.6329 6.76408Z"
      fill="white"
    />
    <path
      d="M24.773 4.46894C24.773 3.43588 24.7638 2.40282 24.7761 1.36976C24.7852 0.535373 24.287 0.428401 23.5994 0.425344C22.9056 0.422288 22.4227 0.553708 22.4257 1.37587C22.4318 3.4756 22.4349 5.57228 22.4227 7.67201C22.4165 8.51252 22.9239 8.61033 23.5994 8.61338C24.2809 8.61644 24.7852 8.50029 24.7791 7.66895C24.77 6.60533 24.7761 5.53866 24.7761 4.47504C24.7761 4.46893 24.773 4.46894 24.773 4.46894Z"
      fill="white"
    />
    <path
      d="M16.3831 6.35146C15.3409 5.28173 14.2986 4.20894 13.2503 3.14533C12.584 2.46987 11.5051 2.56462 11.0619 3.39901C10.9305 3.64963 10.9947 4.19061 11.1873 4.39538C12.7063 6.02138 14.262 7.61375 15.8482 9.1725C16.0622 9.38034 16.5512 9.48425 16.8476 9.40173C18.9076 8.83324 18.9015 8.81491 18.9321 6.65405C18.9565 4.96082 18.981 3.27064 18.9993 1.5774C19.0054 1.03337 19.0268 0.467943 18.2383 0.437379C17.248 0.397646 16.652 0.779688 16.6368 1.56823C16.6062 3.1331 16.6245 4.69797 16.6245 6.25977C16.5451 6.29339 16.4625 6.3209 16.3831 6.35146Z"
      fill="white"
    />
    <path
      d="M11.3003 8.10303C10.579 7.36339 9.84857 6.62986 9.13643 5.88105C8.56184 5.27588 8.12784 5.5479 7.63576 6.0247C7.13758 6.5076 6.88389 6.93856 7.45849 7.52538C8.9286 9.023 10.3957 10.5237 11.8505 12.0335C12.4343 12.6387 12.8652 12.3544 13.3512 11.8838C13.8402 11.41 14.1214 10.973 13.5345 10.3831C12.7857 9.63122 12.0492 8.86407 11.3003 8.10303C11.3034 8.10303 11.3034 8.10303 11.3003 8.10303Z"
      fill="white"
    />
    <path
      d="M6.61224 15.3131C5.11767 15.2764 3.62311 15.2367 2.1316 15.2061C1.18107 15.1878 0.475049 16.0099 0.744009 16.9146C0.823475 17.1867 1.24831 17.5259 1.52644 17.5412C3.74842 17.6451 5.97651 17.6971 8.20154 17.7062C8.49801 17.7062 8.92285 17.4373 9.07872 17.1714C10.1576 15.3253 10.1393 15.3162 8.65083 13.7482C7.48635 12.5196 6.32188 11.2909 5.15434 10.0684C4.77841 9.67408 4.39943 9.25536 3.8126 9.78411C3.07602 10.4473 2.91708 11.1381 3.455 11.7127C4.52473 12.8527 5.63114 13.9591 6.72226 15.0808C6.68558 15.1572 6.64891 15.2336 6.61224 15.3131Z"
      fill="white"
    />
    <path
      d="M4.19734 20.1145C3.16429 20.09 2.13123 20.0748 1.09818 20.035C0.263795 20.0045 0.144599 20.5026 0.12626 21.1873C0.104866 21.8811 0.224061 22.367 1.04928 22.3854C3.14595 22.4312 5.24567 22.4801 7.34234 22.5443C8.18284 22.5688 8.29287 22.0645 8.31121 21.389C8.32955 20.7074 8.22563 20.2001 7.3943 20.1878C6.33068 20.1726 5.26401 20.1389 4.20039 20.1114C4.19734 20.1114 4.19734 20.1145 4.19734 20.1145Z"
      fill="white"
    />
    <path
      d="M5.87535 28.5471C4.78117 29.5649 3.68394 30.5796 2.59282 31.6004C1.89902 32.2514 1.96931 33.3303 2.79453 33.7918C3.0421 33.9293 3.58308 33.8774 3.79091 33.691C5.45357 32.2117 7.08262 30.6957 8.6811 29.1461C8.89504 28.9383 9.00813 28.4523 8.93478 28.1528C8.41825 26.0775 8.39686 26.0836 6.23906 26.0011C4.54889 25.9369 2.85566 25.8697 1.16549 25.8086C0.621459 25.7902 0.0560282 25.7535 0.00712636 26.5421C-0.0570573 27.5293 0.312765 28.1375 1.09825 28.1711C2.66005 28.2414 4.22492 28.2598 5.78978 28.2995C5.82034 28.3851 5.84785 28.4676 5.87535 28.5471Z"
      fill="white"
    />
    <path
      d="M7.50416 33.6695C6.74618 34.3725 5.99737 35.0846 5.23022 35.7784C4.61283 36.3377 4.87263 36.7778 5.3372 37.2821C5.80788 37.7926 6.23271 38.0585 6.83176 37.4961C8.363 36.0627 9.90036 34.6323 11.4469 33.2141C12.0673 32.6456 11.7922 32.2086 11.3338 31.7104C10.8723 31.2091 10.4413 30.9188 9.83923 31.4903C9.06597 32.2238 8.28047 32.9421 7.50416 33.6695C7.5011 33.6695 7.5011 33.6695 7.50416 33.6695Z"
      fill="white"
    />
    <path
      d="M14.5948 38.5355C14.5214 40.027 14.445 41.5215 14.3778 43.0131C14.335 43.9605 15.1388 44.688 16.0527 44.4404C16.3247 44.367 16.6762 43.9514 16.6976 43.6732C16.8565 41.4543 16.9635 39.2293 17.0246 37.0042C17.0338 36.7077 16.774 36.2768 16.5111 36.1148C14.6926 34.9931 14.6804 35.0084 13.0788 36.4571C11.8227 37.591 10.5665 38.725 9.31338 39.865C8.90994 40.2318 8.48205 40.5985 8.99552 41.1976C9.64041 41.9494 10.3281 42.1267 10.9149 41.601C12.0824 40.5588 13.2133 39.4799 14.3625 38.4163C14.4389 38.459 14.5184 38.4957 14.5948 38.5355Z"
      fill="white"
    />
    <path
      d="M19.335 41.0633C19.2861 42.0964 19.2433 43.1294 19.1822 44.1594C19.1302 44.9938 19.6254 45.1253 20.31 45.1619C21.0038 45.1986 21.4928 45.0916 21.5295 44.2695C21.6242 42.1728 21.7251 40.0761 21.8412 37.9825C21.8871 37.142 21.3858 37.0197 20.7104 36.9861C20.0288 36.9494 19.5184 37.0411 19.4878 37.8725C19.4451 38.9361 19.387 39.9997 19.335 41.0633Z"
      fill="white"
    />
    <path
      d="M27.8077 39.5926C28.798 40.7113 29.7852 41.833 30.7785 42.9485C31.4112 43.6576 32.4931 43.6148 32.976 42.8018C33.1197 42.5573 33.083 42.0163 32.8996 41.8024C31.4631 40.1031 29.9869 38.4373 28.4771 36.8022C28.2753 36.5821 27.7894 36.4568 27.4899 36.524C25.4024 36.9917 25.4085 37.01 25.274 39.1678C25.167 40.858 25.06 42.5451 24.9592 44.2353C24.9256 44.7793 24.8767 45.3417 25.6652 45.412C26.6524 45.5006 27.2667 45.1461 27.3217 44.3606C27.4287 42.7988 27.4868 41.237 27.5632 39.6752C27.6457 39.6446 27.7283 39.6201 27.8077 39.5926Z"
      fill="white"
    />
    <path
      d="M32.9703 38.0921C33.6549 38.8654 34.3487 39.6325 35.0242 40.415C35.5682 41.0476 36.0144 40.797 36.531 40.3447C37.0536 39.8862 37.3287 39.4705 36.7816 38.8562C35.3848 37.2883 33.9942 35.7204 32.6127 34.1402C32.0595 33.5076 31.6133 33.7704 31.1059 34.2166C30.5924 34.6659 30.2929 35.0877 30.8492 35.7051C31.5613 36.4936 32.2612 37.2944 32.9703 38.0921C32.9672 38.0921 32.9672 38.0921 32.9703 38.0921Z"
      fill="white"
    />
    <path
      d="M42.9702 22.3791C42.9702 16.4681 47.8023 11.8193 53.5605 11.8193C57.3412 11.8193 60.7308 13.7693 62.531 17.0091C63.0719 17.9107 62.7113 18.8398 61.99 19.1699C61.2992 19.5306 60.3701 19.3502 60.0095 18.6289C58.6005 16.2878 56.2287 14.8788 53.5575 14.8788C49.4467 14.8788 46.0266 18.2683 46.0266 22.3791C46.0266 26.5205 49.4467 29.7603 53.5575 29.7603C56.0484 29.7603 58.4171 28.501 59.8291 26.3402C60.1898 25.6189 61.2992 25.4385 61.8097 25.9795C62.531 26.3402 62.8886 27.2693 62.3506 27.9601C60.3701 31.0195 57.1609 32.8197 53.5605 32.8197C47.8023 32.8228 42.9702 28.1404 42.9702 22.3791Z"
      fill="white"
    />
    <path
      d="M65.7889 14.4328C65.5291 14.173 65.3579 13.8001 65.3579 13.4272C65.3579 13.0543 65.5291 12.6815 65.7889 12.4217C66.0487 12.1619 66.4215 11.9907 66.7944 11.9907C67.1673 11.9907 67.5402 12.1619 67.7999 12.4217C68.0597 12.6815 68.2003 13.0543 68.2003 13.4272C68.2003 13.8001 68.0567 14.173 67.7999 14.4328C67.5402 14.6926 67.1673 14.8331 66.7944 14.8331C66.4215 14.8331 66.0456 14.6926 65.7889 14.4328ZM65.3579 30.6835V19.1151C65.3579 18.3388 65.9906 17.7092 66.7944 17.7092C67.5707 17.7092 68.2003 18.3419 68.2003 19.1151V30.6835C68.2003 31.4874 67.5677 32.12 66.7944 32.12C65.9906 32.117 65.3579 31.4874 65.3579 30.6835Z"
      fill="white"
    />
    <path
      d="M79.741 19.1119C79.741 19.8882 79.1083 20.5484 78.3045 20.5484H76.2659V30.6834C76.2659 31.4597 75.6332 32.1199 74.8294 32.1199C74.0531 32.1199 73.4235 31.4597 73.4235 30.6834V20.5484H71.3573C70.581 20.5484 69.9514 19.8882 69.9514 19.1119C69.9514 18.3356 70.5841 17.706 71.3573 17.706H73.4235V11.9631C73.4235 11.1867 74.0561 10.5571 74.8294 10.5571C75.6332 10.5571 76.2659 11.1898 76.2659 11.9631V17.706H78.3045C79.1083 17.706 79.741 18.3387 79.741 19.1119Z"
      fill="white"
    />
    <path
      d="M93.0333 19.2008V33.3549C93.0333 36.5977 90.3651 39.2689 87.0918 39.2689C84.8239 39.2689 82.7853 37.9761 81.7798 35.9375C81.4344 35.2498 81.7217 34.3879 82.44 34.0425C83.1276 33.6972 83.9895 34.015 84.3074 34.7027C84.8545 35.7663 85.9151 36.4265 87.0918 36.4265C88.8155 36.4265 90.1909 35.0481 90.1909 33.3549V31.2888C89.329 31.8053 88.3235 32.0926 87.2323 32.0926C84.0751 32.0926 81.4894 29.5374 81.4619 26.3802C81.4619 26.3527 81.4619 26.3527 81.4619 26.3527V19.2038C81.4619 18.4275 82.0946 17.7979 82.8679 17.7979C83.6442 17.7979 84.2738 18.4306 84.2738 19.2038V26.3252C84.2738 27.9603 85.6216 29.2807 87.2293 29.2807C88.8644 29.2807 90.1879 27.9603 90.1879 26.3252V19.2038C90.1879 18.4275 90.8205 17.7979 91.5938 17.7979C92.4007 17.7918 93.0333 18.4245 93.0333 19.2008Z"
      fill="white"
    />
    <path
      d="M106.919 30.6527C106.919 31.429 106.286 32.0892 105.513 32.0892H96.5851C96.0411 32.0892 95.5215 31.7744 95.2922 31.2853C95.0325 30.7688 95.0905 30.1942 95.4359 29.7633L102.612 20.5177H96.527C95.7507 20.5177 95.1211 19.8575 95.1211 19.0812C95.1211 18.3049 95.7538 17.6753 96.527 17.6753H105.455C106.002 17.6753 106.518 17.9626 106.748 18.4791C107.007 18.9681 106.949 19.5427 106.604 19.9706L99.4275 29.2437H105.513C106.289 29.2467 106.919 29.8794 106.919 30.6527Z"
      fill="white"
    />
    <path
      d="M123.163 24.8824C123.163 25.6587 122.531 26.2883 121.727 26.2883H111.821C112.423 28.0121 114.031 29.2438 115.956 29.2438C116.617 29.2438 117.793 29.1857 119.172 28.2963C119.832 27.8654 120.721 28.2688 121.067 28.9565C121.412 29.6748 121.039 30.4205 120.379 30.8515C118.484 32.1137 117.02 32.0862 115.956 32.0862C111.965 32.0862 108.75 28.8434 108.75 24.8793C108.75 20.8877 111.965 17.6724 115.956 17.6724C119.428 17.6785 123.163 20.203 123.163 24.8824ZM111.821 23.4489H120.116C119.514 21.4409 117.677 20.5209 115.953 20.5209C114.031 20.5209 112.426 21.7557 111.821 23.4489Z"
      fill="white"
    />
    <path
      d="M139.661 24.8822V30.6527C139.661 31.4565 139.029 32.0892 138.225 32.0892C137.449 32.0892 136.819 31.4565 136.819 30.6527V24.8822C136.819 22.4707 134.866 20.5177 132.454 20.5177C130.043 20.5177 128.09 22.4707 128.09 24.8822V30.6527C128.09 31.4565 127.457 32.0892 126.653 32.0892C125.877 32.0892 125.248 31.4565 125.248 30.6527V24.8822C125.248 20.8906 128.49 17.6753 132.454 17.6753C136.419 17.6753 139.661 20.8937 139.661 24.8822Z"
      fill="white"
    />
    <path
      d="M161.2 31.3864C161.2 32.1902 160.54 32.8229 159.763 32.8229H149.858C149.83 32.8229 149.83 32.8229 149.799 32.8229H149.772C148.968 32.8229 148.335 32.1902 148.335 31.3864V14.2187C148.335 13.4149 148.968 12.7822 149.772 12.7822C150.548 12.7822 151.208 13.4149 151.208 14.2187V29.9499H159.763C160.54 29.9499 161.2 30.5826 161.2 31.3864Z"
      fill="white"
    />
    <path
      d="M176.231 25.7322V31.359C176.231 32.1903 175.629 32.7955 174.794 32.7955C174.22 32.7955 173.416 32.1933 173.416 31.359C172.181 32.392 170.802 32.7955 169.167 32.7955C165.121 32.7955 161.902 29.7819 161.902 25.7322C161.902 21.6855 165.118 18.4702 169.167 18.4702C173.214 18.4672 176.231 21.6825 176.231 25.7322ZM173.416 25.7322C173.416 23.3207 171.579 21.2821 169.167 21.2821C166.756 21.2821 164.717 23.3207 164.717 25.7322C164.717 28.1436 166.756 29.9805 169.167 29.9805C171.579 29.9805 173.416 28.1436 173.416 25.7322Z"
      fill="white"
    />
    <path
      d="M193.038 25.5579C193.038 29.5189 189.823 32.7648 185.831 32.7648C181.87 32.7648 178.624 29.522 178.624 25.5579V25.5304V12.6691C178.624 11.8928 179.257 11.2632 180.061 11.2632C180.837 11.2632 181.466 11.8959 181.466 12.6691V19.818C182.671 18.898 184.193 18.354 185.831 18.354C189.823 18.354 193.038 21.5693 193.038 25.5579ZM190.195 25.5579C190.195 23.1464 188.242 21.1934 185.831 21.1934C183.419 21.1934 181.466 23.1464 181.466 25.5273V25.5548C181.466 27.9663 183.419 29.9193 185.831 29.9193C188.242 29.9193 190.195 27.9418 190.195 25.5579Z"
      fill="white"
    />
    <path
      d="M203.454 28.5163C203.454 30.9003 201.531 32.8503 199.147 32.8503C197.109 32.8503 195.473 31.3863 194.926 29.7205C194.667 28.9748 195.272 28.1985 196.018 28.0273C196.794 27.8837 197.338 28.4277 197.711 29.1459C198.111 29.8917 198.457 30.0078 199.147 30.0078C199.951 30.0078 200.611 29.3477 200.611 28.5163C200.611 27.7125 199.951 27.0523 199.147 27.0523C197.998 27.0523 196.907 26.5939 196.103 25.79C195.272 24.9862 194.841 23.8951 194.841 22.7459C194.841 20.3619 196.763 18.4395 199.147 18.4395C201.158 18.4395 202.995 19.8179 203.283 21.4255C203.426 22.2019 202.968 22.9751 202.191 23.1188C201.415 23.2899 200.755 22.7734 200.498 22.0551C200.327 21.5967 199.838 21.2788 199.15 21.2788C198.346 21.2788 197.686 21.939 197.686 22.7428C197.686 23.5467 198.346 24.2068 199.15 24.2068C200.3 24.2068 201.391 24.6653 202.194 25.4691C203.023 26.3035 203.454 27.3671 203.454 28.5163Z"
      fill="white"
    />
  </svg>
);
