import React, { useState } from "react";
import { useDevice } from "../../context/DeviceContext";
import {
  ChatFindrHeader,
  ChatFindrSubHeader,
  PortfolioHeader,
  PortfolioCarousel,
  PortfolioSubHeader,
  ViewFullCaseButton,
} from "../../constants/Portfolio/PortfolioConstants";

function Portfolio() {
  const { isMobile, isExtraLargeScreen } = useDevice();

  const photos = [
    { backgroundColor: "#F1EDE9" },
    { backgroundColor: "#F1EDF1" },
    { backgroundColor: "#F1EDE1" },
  ];

  return (
    <div>
      {isExtraLargeScreen && (
        <div>
          <div
            style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}
            className={`px-[120px] pt-[120px]`}
          >
            {" "}
            <div className="flex justify-between mb-[60px]">
              <div className="w-[700px]">
                <PortfolioHeader fontSize={"45px"} />
              </div>
              <div
                style={{
                  borderBottom: "1px solid var(--Primary-Brand, #1B3275)",
                }}
                className="self-end pb-[15px] pl-[30px]"
              >
                <PortfolioSubHeader />
              </div>
            </div>
          </div>

          <div
            style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}
            className={`pl-[120px] pb-[120px]`}
          >
            <div className="flex items-center">
              <div>
                <div className="mb-[10px] ">
                  <ChatFindrHeader />
                </div>
                <div className="w-[600px] mb-[40px]">
                  <ChatFindrSubHeader />
                </div>
                <div>
                  <ViewFullCaseButton />
                </div>
              </div>
              <PortfolioCarousel
                photos={photos}
                photoWidth={500}
                photoHeight={434}
                gap={20}
              />
            </div>
          </div>
        </div>
      )}

      {!isExtraLargeScreen && (
        <div
          style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}
          className={`${isMobile ? "px-[20px]" : "px-[120px]"} ${
            isExtraLargeScreen ? "py-[120px]" : "py-[60px]"
          }`}
        >
          <div
            style={{
              borderBottom: "1px solid #1B3275",
              width: "fit-content",
            }}
            className="pb-[15px] pr-[30px] mb-[30px]"
          >
            <PortfolioSubHeader />
          </div>
          <div className={`mb-[60px] ${isMobile ? "w-[320px]" : ""}`}>
            <PortfolioHeader fontSize={"35px"} />
          </div>
          <div className="mb-[60px]">
            <PortfolioCarousel
              photos={photos}
              photoWidth={280}
              photoHeight={434}
              gap={20}
            />
          </div>
          <div className="mb-[10px]">
            <ChatFindrHeader />
          </div>
          <div className="mb-[40px]">
            <ChatFindrSubHeader />
          </div>
          <div>
            <ViewFullCaseButton />
          </div>
        </div>
      )}
    </div>
  );
}

export default Portfolio;
