import React from "react";
import { ArrowRightIcon } from "../../icons/icons";
import { useDevice } from "../../context/DeviceContext";
import { Link } from "react-scroll";

export const LandingTitle = ({ fontSize }) => (
  <span>
    <span
      style={{
        color: "var(--Primary-Dark, #161616)",
        fontFamily: "Montserrat",
        fontSize,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "100%", // 80px
        letterSpacing: "-2.4px",
      }}
    >
      Leading <br /> Tampa
    </span>
    <span
      style={{
        background:
          "var(--Primary-Gradient, linear-gradient(90deg, #6677F1 0%, #1B3275 100%))",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontFamily: "Montserrat",
        fontSize,
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "100%",
        letterSpacing: "-2.4px",
      }}
    >
      {" "}
      Software <br /> Agency
    </span>
  </span>
);

export const LandingSubTitle = ({ width }) => (
  <span
    style={{
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "22.4px",
      maxWidth: width,
      display: "inline-block",
    }}
  >
    At Cityzen Labs, we take pride in our unwavering dedication to innovation
    and our continued impact on the local economy. As one of the top software
    development companies in the Tampa area, we hope to continue to shape the
    landscape over the next decade.
  </span>
);

export const LandingButtonOne = ({ onClick, width }) => {
  const { isMobile } = useDevice();
  return (
    <Link to="contact" smooth={true} duration={500}>
      <button
        onClick={onClick}
        style={{
          width: width,
          border: "1px solid var(--Primary-Gradient, #6677F1)",
          borderRadius: "5px",
          background:
            "var(--Primary-Gradient, linear-gradient(90deg, #6677F1 0%, #1B3275 100%))",
        }}
      >
        <div className="flex justify-center items-center">
          <div
            className="pl-[20px] pr-[6px] text-white"
            style={{
              fontFamily: "Montserrat",
              fontSize: "15.84px",
              fontWeight: "500",
              lineHeight: "24px",
            }}
          >
            Get In Touch
          </div>
          <div className={`p-[18px]`}>
            <ArrowRightIcon color="white" />
          </div>
        </div>
      </button>
    </Link>
  );
};

export const LandingButtonTwo = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        border: "1px solid var(--Primary-Gradient, #6677F1)",
        borderRadius: "5px",
      }}
    >
      <div
        className="px-[20px] "
        style={{
          fontFamily: "Montserrat",
          fontSize: "15.84px",
          fontWeight: "500",
          lineHeight: "24px",
        }}
      >
        Explore
      </div>
    </button>
  );
};
