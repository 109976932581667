import React, { useEffect, useRef } from "react";
import { Logo, WhiteLogo } from "../../Logo/Logo";
import { useDevice } from "../../context/DeviceContext";

export const FooterSubHeader = () => {
  return (
    <span
      className="pr-[30px] pb-[15px] border-b "
      style={{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "100%",
        color: "#FFF",
        borderBottom: "1px solid var(--Primary-Brand, #FFF)",
      }}
    >
      Career
    </span>
  );
};

export const FooterHeader = ({ fontSize }) => {
  return (
    <span>
      <span
        style={{
          fontFamily: "Montserrat",
          fontSize: fontSize,
          fontWeight: "500",
          lineHeight: "120%",
          letterSpacing: "-3px",
          color: "#FFF",
        }}
      >
        Join Our Team
      </span>
    </span>
  );
};

export const FooterInput = () => {
  return (
    <div className="w-[390px]">
      <input
        className="w-full py-[20px] px-[25px]"
        style={{
          border: "1px solid #9F9F9F",
          backgroundColor: "transparent",
          color: "var(--Secondary-Light, rgba(255, 255, 255, 0.60))",
          fontfamily: "Montserrat",
          fontSize: "13px",
          fontStyle: "normal",
          fontWeight: "300",
          lineHeight: "120%",
          letterSpacing: "-0.39px",
          borderRadius: "5px",
        }}
        placeholder="Your Email*"
      />
    </div>
  );
};

export const FindOutMore = () => {
  return (
    <div
      style={{
        fontFamily: "Montserrat",
        fontSize: "20px",
        fontWeight: "300",
        lineHeight: "120%",
        letterSpacing: "-0.6px",
        color: "#FFF",
      }}
    >
      Are you a developer or designer passionate about technology and
      innovation? Cityzen Labs is looking for talented individuals to join the
      team. Find out more!
    </div>
  );
};

export const FooterCarousel = () => {
  const items = [
    "UI/UX Designer",
    "Frontend Developer",
    "Backend Developer",
    "Product Manager",
    "Marketing Director",
    "UI/UX Designer",
    "Frontend Developer",
    "Backend Developer",
    "Product Manager",
    "Marketing Director",
    "UI/UX Designer",
    "Frontend Developer",
    "Backend Developer",
    "Product Manager",
    "Marketing Director",
    "UI/UX Designer",
    "Frontend Developer",
    "Backend Developer",
    "Product Manager",
    "Marketing Director",
    "UI/UX Designer",
    "Frontend Developer",
    "Backend Developer",
    "Product Manager",
    "Marketing Director",
    "UI/UX Designer",
    "Frontend Developer",
    "Backend Developer",
    "Product Manager",
    "Marketing Director",
  ];

  const carouselRef = useRef(null);
  const itemWidth = 200 + 20; // Width of each item including margin

  useEffect(() => {
    const carousel = carouselRef.current;
    const totalWidth = items.length * itemWidth;
    let scrollPosition = 0;

    const step = () => {
      // Calculate the new position
      scrollPosition = (scrollPosition + 1) % totalWidth;
      carousel.style.transform = `translateX(-${scrollPosition}px)`;

      requestAnimationFrame(step);
    };

    requestAnimationFrame(step);
  }, []);

  const itemStyle = {
    flex: "none",
    width: "fit-content",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 5px",
    border: "1px solid #FFF",
    borderRadius: "5px",
    backgroundColor: "transparent",
    color: "white",
    fontSize: "20px",
    paddingRight: "50px",
    paddingLeft: "50px",
    paddingTop: "40px",
    paddingBottom: "40px",
    fontFamily: "Montserrat",
    fontWeight: "500",
    lineHeight: "130%",
    letterSpacing: "-0.4px",
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div ref={carouselRef} style={{ display: "flex", whiteSpace: "nowrap" }}>
        {items.map((item, index) => (
          <div key={index} style={itemStyle}>
            {item}
          </div>
        ))}
        {items.map(
          (
            item,
            index, // Duplicate items for a seamless loop
          ) => (
            <div key={`extra-${index}`} style={itemStyle}>
              {item}
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export const FooterSocials = () => {
  return (
    <div>
      <WhiteLogo />
    </div>
  );
};

export const FooterSections = () => {
  const { isExtraLargeScreen } = useDevice();
  const items = ["Services", "Process", "Case Studies", "FAQ"];

  const styling = {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: "300",
    lineHeight: "normal",
    color: "#FFF",
  };
  return (
    <div>
      {isExtraLargeScreen && (
        <div className="flex gap-[40px]">
          {items.map((item) => (
            <div style={styling}>{item}</div>
          ))}
        </div>
      )}

      {!isExtraLargeScreen && (
        <div>
          <div className="flex gap-[40px]">
            <div style={styling}>Design</div>
            <div style={styling}>Develop</div>
            <div style={styling}>Support</div>
          </div>
          <div className="flex gap-[40px] mt-[40px]">
            <div style={styling}>Our Processs</div>
            <div style={styling}>Our Portfolio</div>
            <div style={styling}>FAQ</div>
          </div>
        </div>
      )}
    </div>
  );
};

export const FooterTradeMark = () => {
  const { isExtraLargeScreen } = useDevice();
  const cityzenStyling = {
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "700",
    lineHeight: "150%",
    color: "#FFF",
    letterSpacing: "0.15px",
  };

  const style = {
    color: "var(--Secondary-Light, rgba(255, 255, 255, 0.60))",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "150%" /* 22.5px */,
    letterSpacing: "0.15px",
  };

  return (
    <div>
      {isExtraLargeScreen && (
        <div className="flex justify-between">
          <div>
            {" "}
            <span style={style}>@ 2024 {""}</span>
            <span style={cityzenStyling}>Cityzen Labs {""}</span>
            <span style={style}>| All Rights Reserved {""}</span>
          </div>
          <div>
            <span style={style}>Privacy | Terms & Conditions</span>
          </div>
        </div>
      )}

      {!isExtraLargeScreen && (
        <div>
          <div className="pb-[20px]">
            {" "}
            <span style={style}>@ 2024 {""}</span>
            <span style={cityzenStyling}>Cityzen Labs {""}</span>
            <span style={style}>| All Rights Reserved {""}</span>
          </div>
          <div>
            {" "}
            <span style={style}>Privacy | Terms & Conditions</span>
          </div>
        </div>
      )}
    </div>
  );
};
