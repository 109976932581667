import React, { useState } from "react";
import { useDevice } from "../../context/DeviceContext";
export const ProcessSubHeader = () => (
  <span
    className="py-[15px] px-[30px]"
    style={{
      borderBottom:
        "1px solid var(--Secondary-Light, rgba(255, 255, 255, 0.60))",
      color: "var(--Secondary-Light, rgba(255, 255, 255, 0.60))",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "100%",
    }}
  >
    Our Process
  </span>
);

export const ProcessHeader = ({ width }) => {
  const { isMobile } = useDevice();
  return (
    <span
      className="py-[15px] px-[30px]"
      style={{
        textAlign: "center",
        fontFamily: "Montserrat",
        fontSize: isMobile ? "35px" : "45px",
        fontWeight: "500",
        lineHeight: "120%", // 54px
        letterSpacing: "-3px",
        display: "inline-block",
        width: width,
      }}
    >
      <span
        style={{
          color: "var(--Secondary-Light, rgba(255, 255, 255, 0.60))",
        }}
      >
        Our Foundation for{" "}
      </span>
      <span
        style={{
          color: "white",
        }}
      >
        Excellence in App
      </span>
      <span
        style={{
          color: "white",
        }}
      >
        {" "}
        Development
      </span>
    </span>
  );
};

export const ProcessButtons = () => {
  const { isMobile } = useDevice();
  const phases = [
    { label: "Discovery", imageUrl: "discoverProcess" },
    { label: "Design", imageUrl: "designProcess" },
    { label: "Development", imageUrl: "developProcess" },
  ];
  const [activePhase, setActivePhase] = useState(phases[0]);
  const { isExtraLargeScreen } = useDevice();
  const activeStyle = {
    color: "#1B3275",
    background: "white",
    border: "none",
    borderRadius: "10px",
    textAlign: "left",
    fontFamily: "Montserrat",
    fontSize: isMobile ? "20px" : "25px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "120%",
    letterSpacing: "-0.75px",
  };

  const inactiveStyle = {
    color: "white",
    border: "1px solid rgba(255, 255, 255, 0.60)",
    borderRadius: "10px",
    textAlign: "left",
    fontFamily: "Montserrat",
    fontSize: isMobile ? "20px" : "25px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "120%",
    letterSpacing: "-0.75px",
  };

  const activeImageStyle = {
    background:
      "var(--Primary-Gradient, linear-gradient(90deg, #6677F1 0%, #1B3275 100%))",
    borderRadius: "5px",
  };

  // Style for inactive image background
  const inactiveImageStyle = {
    backgroundColor: "white",
    borderRadius: "5px",
  };

  const numberStyle = {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "0.27px",
    color: "white",
  };

  const headerStyle = {
    fontFamily: "Montserrat",
    fontSize: isMobile ? "20px" : "25px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "120%",
    letterSpacing: "-0.75px",
    color: "white",
  };

  const subHeaderStyle = {
    fontFamily: "Montserrat",
    fontSize: isMobile ? "16px" : "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "120%",
    letterSpacing: "-0.48px",
    color: "var(--Secondary-Light, rgba(255, 255, 255, 0.60))",
  };

  const imgKeepAspect = {
    display: "block", // Ensures the image is treated as a block element, helping with aspect ratio maintenance
    maxWidth: "100%", // Ensures the image is not wider than its container
    maxHeight: "100%", // Ensures the image is not taller than its container
    height: "auto", // Maintains aspect ratio based on width adjustments
    width: "auto", // Default to auto, but this can be overridden if necessary
    objectFit: "contain", // Ensures the content is resized to maintain its aspect ratio while fitting within the element's content box
  };

  // Reusable image component
  const StyledImage = ({ src, alt, className }) => {
    return (
      <img style={imgKeepAspect} className={className} src={src} alt={alt} />
    );
  };

  const phaseContent = {
    Discovery: (
      <div>
        <div className="flex mb-[60px]">
          <div
            style={numberStyle}
            className={`${isMobile ? "mr-[40px]" : "mr-[60px]"} py-[8px]"`}
          >
            01
          </div>
          <div>
            <div className="mb-[30px]" style={headerStyle}>
              Specifcations & Planning
            </div>
            <div>
              <div className="flex mb-[20px]">
                <StyledImage
                  className="mr-[20px]"
                  src={`${process.env.PUBLIC_URL}/whiteCheck.png`}
                  alt="White Check"
                />
                <div style={subHeaderStyle}>
                  Solidfy what is being build so we are aligned.
                </div>
              </div>
              <div className="flex mb-[20px]">
                <StyledImage
                  className="mr-[20px]"
                  src={`${process.env.PUBLIC_URL}/whiteCheck.png`}
                  alt="White Check"
                />
                <div style={subHeaderStyle}>
                  Research libraries and services that will be integrated with
                  the software.
                </div>
              </div>
              <div className="flex">
                <StyledImage
                  className="mr-[20px]"
                  src={`${process.env.PUBLIC_URL}/whiteCheck.png`}
                  alt="White Check"
                />
                <div style={subHeaderStyle}>
                  Decide on coding language architecture and other unique
                  elements.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div
            style={numberStyle}
            className={`${isMobile ? "mr-[40px]" : "mr-[60px]"} py-[8px]"`}
          >
            02
          </div>
          <div>
            <div className="mb-[30px]" style={headerStyle}>
              Estimates & Timeline
            </div>

            <div style={subHeaderStyle}>
              Using all the information from the specs and designs, we build a
              spreadsheet estimating all the tasks involved, timeline and total
              cost.
            </div>
          </div>
        </div>
      </div>
    ),

    Design: (
      <div>
        <div className="flex mb-[60px]">
          <div
            style={numberStyle}
            className={`${isMobile ? "mr-[40px]" : "mr-[60px]"} py-[8px]"`}
          >
            01
          </div>
          <div>
            <div className="mb-[30px]" style={headerStyle}>
              Designs, Wireframe & Prototype
            </div>

            <div style={subHeaderStyle}>
              Build out designs to determine what the software/app/website will
              look like, and how it will function (emphasis on user experience).
            </div>
          </div>
        </div>
      </div>
    ),

    Development: (
      <div>
        {" "}
        <div className="flex mb-[60px]">
          <div
            style={numberStyle}
            className={`${isMobile ? "mr-[40px]" : "mr-[60px]"} py-[8px]"`}
          >
            01
          </div>
          <div>
            <div className="mb-[30px]" style={headerStyle}>
              Build
            </div>

            <div style={subHeaderStyle}>
              Developers build the software, adding new features, fixing old
              features & constantly improving code architecture & scalability.
            </div>
          </div>
        </div>
        <div className="flex mb-[60px]">
          <div
            style={numberStyle}
            className={`${isMobile ? "mr-[40px]" : "mr-[60px]"} py-[8px]"`}
          >
            02
          </div>
          <div>
            <div className="mb-[30px]" style={headerStyle}>
              Test
            </div>

            <div style={subHeaderStyle}>
              Automated pipelines test the latest build, identifying any bugs
              that need to be fixed or features that need improving.
            </div>
          </div>
        </div>
        <div className="flex mb-[60px]">
          <div
            style={numberStyle}
            className={`${isMobile ? "mr-[40px]" : "mr-[60px]"} py-[8px]"`}
          >
            03
          </div>
          <div>
            <div className="mb-[30px]" style={headerStyle}>
              Deploy
            </div>

            <div style={subHeaderStyle}>
              Our deployment engineers release the newest build to the testing
              platforms, or live, to the App Store, Google Play store or
              website.
            </div>
          </div>
        </div>
        <div className="flex mb-[60px]">
          <div
            style={numberStyle}
            className={`${isMobile ? "mr-[40px]" : "mr-[60px]"} py-[8px]"`}
          >
            04
          </div>
          <div>
            <div className="mb-[30px]" style={headerStyle}>
              Measure
            </div>

            <div style={subHeaderStyle}>
              Our product team measures the progress & effectiveness of the
              latest build.
            </div>
          </div>
        </div>
        <div className="flex mb-[60px]">
          <div
            style={numberStyle}
            className={`${isMobile ? "mr-[40px]" : "mr-[60px]"} py-[8px]"`}
          >
            05
          </div>
          <div>
            <div className="mb-[30px]" style={headerStyle}>
              Maintain
            </div>

            <div style={subHeaderStyle}>
              Should we exit the software development loop, we have lightweight
              plans available for continued, long-term maintenance
            </div>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <div>
      {isExtraLargeScreen && (
        <div className="flex gap-[120px]">
          <div>
            {phases.map((phase) => (
              <button
                className="p-[20px] mb-[30px] w-[388px] flex items-center"
                key={phase.label} // React needs a unique key for list items
                onClick={() => setActivePhase(phase)}
                style={
                  activePhase.label === phase.label
                    ? activeStyle
                    : inactiveStyle
                }
              >
                <img
                  src={`${process.env.PUBLIC_URL}/${phase.imageUrl}.png`}
                  alt={phase.label}
                  className="p-[10px] mr-[30px]"
                  style={
                    activePhase.label === phase.label
                      ? activeImageStyle
                      : inactiveImageStyle
                  }
                />
                {phase.label}
              </button>
            ))}
          </div>

          <div className="content-display">
            {/* Display content based on the active phase */}
            {phaseContent[activePhase.label]}
          </div>
        </div>
      )}

      {!isExtraLargeScreen && (
        <div>
          <div>
            {phases.map((phase) => (
              <div key={phase.label} className="mb-[30px] ">
                <button
                  className="p-[20px] w-full flex items-center"
                  onClick={() => setActivePhase(phase)}
                  style={
                    activePhase.label === phase.label
                      ? activeStyle
                      : inactiveStyle
                  }
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/${phase.imageUrl}.png`}
                    alt={phase.label}
                    className="p-[10px] mr-[30px]"
                    style={
                      activePhase.label === phase.label
                        ? activeImageStyle
                        : inactiveImageStyle
                    }
                  />
                  {phase.label}
                </button>
                {activePhase.label === phase.label && (
                  <div className="content-display mt-[30px]">
                    {phaseContent[activePhase.label]}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
