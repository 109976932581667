import React, { createContext, useContext, useState, useEffect } from "react";

const DeviceContext = createContext();

export const useDevice = () => useContext(DeviceContext);

export const DeviceProvider = ({ children }) => {
  const [device, setDevice] = useState(getDeviceFlags(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setDevice(getDeviceFlags(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <DeviceContext.Provider value={device}>{children}</DeviceContext.Provider>
  );
};

const getDeviceFlags = (width) => {
  return {
    isExtraLargeScreen: width >= 1200,
    isLargeScreen: width >= 992 && width < 1200,
    isMediumScreen: width >= 768 && width < 992,
    isSmallScreen: width >= 576 && width < 768,
    isMobile: width < 576,
  };
};
