import React from "react";
import {
  LandingButtonOne,
  LandingButtonTwo,
  LandingSubTitle,
  LandingTitle,
} from "../../constants/Landing/LandingConstants";
import { useDevice } from "../../context/DeviceContext";

function Landing() {
  const { isExtraLargeScreen, isMobile } = useDevice();
  return (
    <div>
      <div
        style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}
        className={`${
          isMobile ? "px-[20px]" : "flex justify-between px-[120px]"
        } ${isExtraLargeScreen ? "py-[120px]" : "py-[60px]"}`}
      >
        <div>
          <div>
            <LandingTitle fontSize={!isMobile ? "80px" : "42px"} />
          </div>
          <div className="py-[30px]">
            <LandingSubTitle width={isMobile ? "100%" : "570px"} />
          </div>
          <div className="flex gap-[10px]">
            <LandingButtonOne width={isMobile ? "100%" : ""} />
            <LandingButtonTwo />
          </div>
        </div>
        {isExtraLargeScreen && (
          <div>
            {" "}
            <img
              src={`${process.env.PUBLIC_URL}/computer.png`}
              alt="Computer"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Landing;
