import React from "react";
import { useDevice } from "../../context/DeviceContext";
import {
  ServicesHeader,
  ServicesSubHeader,
  FullServicesDetails,
} from "../../constants/Services/ServicesConstants";

function Services() {
  const { isMobile, isExtraLargeScreen } = useDevice();
  return (
    <div style={{ backgroundColor: "#F6F6FF", width: "100%" }}>
      <div style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}>
        <div
          className={` ${
            isMobile ? "px-[20px] py-[100px]" : "px-[120px] py-[120px]"
          }`}
        >
          <div className="flex justify-center mb-[20px]">
            <ServicesSubHeader />
          </div>

          <div
            className={`flex justify-center ${
              isExtraLargeScreen ? "mb-[80px]" : "mb-[60px]"
            }`}
          >
            <ServicesHeader width={isExtraLargeScreen ? "590px" : ""} />
          </div>

          <div>
            <FullServicesDetails />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
