import React from "react";
import { useDevice } from "../../context/DeviceContext";
import {
  FindOutMore,
  FooterCarousel,
  FooterHeader,
  FooterInput,
  FooterSections,
  FooterSocials,
  FooterSubHeader,
  FooterTradeMark,
} from "../../constants/Footer/FooterConstants";
import { FaceBook, GitHub, LinkedIn, Twitter } from "../../icons/icons";

function Footer() {
  const { isMobile, isExtraLargeScreen } = useDevice();
  return (
    <div className="bg-[#161616]">
      <div style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}>
        {" "}
        <div
          className={` ${
            isMobile ? "px-[20px] py-[100px]" : "px-[120px] py-[120px]"
          }`}
        >
          {isExtraLargeScreen && (
            <div className="w-full">
              <div className="mb-[20px]">
                <FooterSubHeader />
              </div>
              <div>
                <FooterHeader fontSize={"45px"} />
              </div>
              <div className="grid grid-cols-2 grid-rows-1 gap-[150px]">
                <div className="flex gap-[10px] items-center">
                  <FooterInput />
                  <div
                    className="bg-white p-[22px]"
                    style={{ borderRadius: "5px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                    >
                      <path
                        d="M0 6.92529H15M15 6.92529L8.5 0.425293M15 6.92529L8.5 13.4253"
                        stroke="#161616"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <FindOutMore />
                </div>
              </div>
            </div>
          )}

          {!isExtraLargeScreen && (
            <div>
              <div className="mb-[20px]">
                <FooterSubHeader />
              </div>
              <div>
                <FooterHeader fontSize={"45px"} />
              </div>
              <div className="mt-[30px]">
                <FindOutMore />
              </div>
              <div className="flex gap-[10px] mt-[30px] items-center">
                <FooterInput />
                <div
                  className="bg-white p-[22px]"
                  style={{ borderRadius: "5px" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                  >
                    <path
                      d="M0 6.92529H15M15 6.92529L8.5 0.425293M15 6.92529L8.5 13.4253"
                      stroke="#161616"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="relative mt-[80px] bottom-[120px] w-full">
          <FooterCarousel />
        </div>
        <div
          className={` ${
            isMobile ? "px-[20px] pb-[30px]" : "px-[120px] pb-[30px]"
          }`}
        >
          <div className="xl:flex justify-between items-center border-t border-b pt-[50px] pb-[50px]">
            <div className="xl:mb-[0px] mb-[30px]">
              {" "}
              <FooterSocials />
            </div>
            <div className="flex gap-[40px]">
              <div>
                <FaceBook />
              </div>
              <div>
                <Twitter />
              </div>
              <div>
                <LinkedIn />
              </div>
            </div>
          </div>

          <div className="py-[50px] border-b ">
            <FooterSections />
          </div>
          <div className="pt-[50px]">
            <FooterTradeMark />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
