import React from "react";
import { useDevice } from "../../context/DeviceContext";
import {
  FAQAccordian,
  FAQBenefits,
  FAQCost,
  FAQDescription,
  FAQHeader,
  FAQNativeAndHybrid,
  FAQSupport,
  FAQTimeFrame,
} from "../../constants/FAQ/FAQConstants";

function FAQ() {
  const { isMobile, isExtraLargeScreen } = useDevice();
  return (
    <div
      style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}
      className={`${isMobile ? "px-[20px]" : "px-[120px]"} ${
        isExtraLargeScreen ? "py-[120px]" : "py-[60px]"
      }`}
    >
      {isExtraLargeScreen && (
        <div>
          {" "}
          <div className="flex items-center gap-[80px]">
            <div className="flex-shrink-0">
              <img
                src={`${process.env.PUBLIC_URL}/faqMan.png`}
                alt="Computer"
                className="max-w-full h-auto"
              />
            </div>
            <div>
              <div className="mb-[20px]">
                <FAQHeader fontSize={"45px"} />
              </div>
              <div className="w-[740px]">
                {" "}
                <FAQDescription />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-[60px] mt-[80px]">
            <div>
              <FAQCost fontSize={"25px"} />
              <div className="mt-[10px]">
                <FAQTimeFrame fontSize={"25px"} />
              </div>
              <div className="mt-[10px]">
                <FAQBenefits fontSize={"25px"} />
              </div>
            </div>
            <div>
              <FAQNativeAndHybrid fontSize={"25px"} />
              <div className="mt-[10px]">
                <FAQSupport fontSize={"25px"} />
              </div>
            </div>
          </div>
        </div>
      )}

      {!isExtraLargeScreen && (
        <div>
          <div className="mb-[20px]">
            <FAQHeader fontSize={"35px"} />
          </div>
          <div>
            {" "}
            <FAQDescription />
          </div>
          <div className="mt-[60px]">
            <FAQCost fontSize={"20px"} />
            <div className="mt-[10px]">
              <FAQTimeFrame fontSize={"20px"} />
            </div>
            <div className="mt-[10px]">
              <FAQBenefits fontSize={"20px"} />
            </div>
            <div className="mt-[10px]">
              <FAQNativeAndHybrid fontSize={"20px"} />
            </div>
            <div className="mt-[10px]">
              <FAQSupport fontSize={"20px"} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FAQ;
