import React, { useState } from "react";

export const PortfolioHeader = ({ fontSize }) => {
  return (
    <div
      style={{
        fontFamily: "Montserrat",
        fontSize: fontSize,
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "120%",
        letterSpacing: "-3px",
      }}
    >
      <span className="text-[#161616]">Unveiling Our Portfolio - </span>{" "}
      <span className="text-[#6677F1]">
        Transformative Web and Mobile Applications{" "}
      </span>
    </div>
  );
};

export const PortfolioSubHeader = () => {
  return (
    <div
      style={{
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "100%",
        color: "#1B3275",
      }}
    >
      Portfolio
    </div>
  );
};

export const ChatFindrHeader = () => {
  return (
    <div
      style={{
        color: "var(--Primary-Brand, #1B3275)",
        fontFamily: "Montserrat",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "120%" /* 48px */,
        letterSpacing: "-1.2px",
      }}
    >
      ChatFindr
    </div>
  );
};

export const ChatFindrSubHeader = () => {
  return (
    <div
      style={{
        color: "var(--Secondary-Dark, #4F4F4F)",
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "150%",
      }}
    >
      Whether seeking like-minded individuals or exploring diverse perspectives,
      ChatFindr empowers users to discover communities aligned with their
      interests, passions, and values.
    </div>
  );
};

export const CaseStudySection = ({ isMobile }) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div>
          <div className="mb-[10px]">
            <ChatFindrHeader />
          </div>
          <div className="w-[600px]">
            <ChatFindrSubHeader />
          </div>
          <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">
            View full case
          </button>
        </div>
        <div className="flex justify-center">
          <img
            src={`${process.env.PUBLIC_URL}/path/to/your/image.png`}
            alt="ChatFindr app screenshots"
            className="max-w-full h-auto"
          />
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <button className="p-2">
          <span className="material-icons">chevron_left</span>
        </button>
        <button className="p-2">
          <span className="material-icons">chevron_right</span>
        </button>
        <p className="ml-4">Slide to see more</p>
      </div>
    </div>
  );
};

export const ViewFullCaseButton = () => {
  return (
    <div>
      <button
        className="py-[13px] px-[20px]"
        style={{
          borderRadius: "5px",
          border: "1px solid var(--Primary-Gradient, #6677F1)",
          color: "var(--Primary-Dark, #161616)",
          fontFamily: "Montserrat",
          fontSize: "15px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "24px",
        }}
      >
        View Full Case
      </button>
    </div>
  );
};

export const PortfolioLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 12L7.0575 10.9425L2.8725 6.75L12 6.75L12 5.25L2.8725 5.25L7.0575 1.0575L6 -1.47821e-06L-4.29138e-07 6L6 12Z"
        fill="#161616"
      />
    </svg>
  );
};

export const PortfolioRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z"
        fill="url(#paint0_linear_9_1924)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9_1924"
          x1="0"
          y1="6"
          x2="12"
          y2="6"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6677F1" />
          <stop offset="1" stop-color="#1B3275" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const SlideToSeeMore = () => {
  return (
    <div
      style={{
        color: "var(--Primary-Brand, #1B3275)",
        fontFamily: "Montserrat",
        fontSize: "13px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "120%",
        letterSpacing: "-0.39px",
      }}
    >
      SLIDE TO SEE MORE{" "}
    </div>
  );
};

export const PortfolioCarousel = ({ photos, photoWidth, photoHeight, gap }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1,
    );
  };

  return (
    <div style={{ maxWidth: "1440px", margin: "0 auto", width: "100%" }}>
      <div className="relative" style={{ overflow: "hidden", width: "100%" }}>
        <div
          className="flex transition-transform duration-500"
          style={{
            transform: `translateX(-${currentIndex * (photoWidth + gap)}px)`,
            width: `calc(${photos.length * (photoWidth + gap)}px - ${gap}px)`,
          }}
        >
          {photos.map((photo, index) => (
            <div
              key={index}
              className="border flex-shrink-0"
              style={{
                backgroundColor: photo.backgroundColor,
                width: `${photoWidth}px`,
                height: `${photoHeight}px`,
                marginRight: index < photos.length - 1 ? `${gap}px` : "0",
              }}
            ></div>
          ))}
        </div>
      </div>
      <div className="flex gap-[10px] mt-[30px] items-center mb-[60px]">
        <button
          type="button"
          onClick={handlePrev}
          className="p-[14px] border"
          style={{ borderRadius: "5px" }}
        >
          <PortfolioLeftIcon />
        </button>
        <button
          type="button"
          onClick={handleNext}
          className="p-[14px] border"
          style={{ borderRadius: "5px" }}
        >
          <PortfolioRightIcon />
        </button>
        <div>
          <SlideToSeeMore />
        </div>
      </div>
    </div>
  );
};
